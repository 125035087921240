<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <label style="line-height:1.5">Check one of the following and list all that apply to the relationship
                    between you and the vendor. </label>
                <OmniSelect placeholder="Select Relationship" name="Media" :showLabel="false" label="Select Media"
                    :options="RelationshipVendors" v-model="ProdEXPAmend.EmployeeVendorRelationship" />
            </div>
            <div class="d-block row col-lg-8 mt-2 mx-auto" v-for="VendorRelation in ProdEXPAmend.EmployeeVendorRelationship"
                :key="VendorRelation">
                <span v-if="!IsNoneSelected">
                    <label>{{ GetRelationshipLabel(VendorRelation) }}</label>
                    <b-form-input @focus="ResetDirty(VendorRelation)" @blur="SetDirty(VendorRelation)" autocomplete="off"
                        class="omniipclass" v-model="ProdEXPAmend.EmployeeVendorRelationshipDetails[VendorRelation]"
                        :placeholder="GetRelationshipLabel(VendorRelation)" :maxlength="100"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.EmployeeVendorRelationshipDetails[VendorRelation].$dirty && !$v.ProdEXPAmend.EmployeeVendorRelationshipDetails[VendorRelation].required">
                        Required field
                    </div>
                </span>
            </div>
            <div class="d-block row col-lg-8 mx-auto mt-3">
                <b-form-group
                    label="Has any form of compensation or gifts of any type been offered to you or any person related to you in exchange for placing an order with this vendor?">
                    <b-form-radio-group id="credit-card"
                        v-model="ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder" name="credit-card">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-10 offset-lg-2" v-if="CompensationReceived">
                <b-form-group>
                    <label class="p-0 label-compensation"> Compensation Received</label>
                    <b-form-input autocomplete="off" id="compensation-dollar" class="form-control col-md-3"
                            placeholder="Compensation" v-model="ProdEXPAmend.CompensationReceived" :maxLength="6"
                            @blur="formatDollars()"></b-form-input>
                        <div v-show="!IsCompensationDollarsValid" class="error-messages">
                            This field can only contain numerical values
                        </div>
                </b-form-group>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                PREVIOUS
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>

    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import {
    required,
    requiredIf
} from 'vuelidate/lib/validators';
export default {
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            // ProdEXPAmend: {
            //     EmployeeVendorRelationship: [],
            //     FormerEmployee: null,
            //     CompensationOrGiftProvided: null
            // },
            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee (name) ",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member(name)",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Vendor of Former employee",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Vendor was former employee of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Vendor has a relationship w/ another Agency employee (list employee name)",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ],
            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        }
    },
    validations: {
        ProdEXPAmend: {
            AnyGiftOrCompensationOfferedToYouToPlaceOrder: {
                required: required
            },
            CompensationReceived: {
                required: requiredIf(function (model) {
                    return model.AnyGiftOrCompensationOfferedToYouToPlaceOrder == "true";
                })
            },
            EmployeeVendorRelationship: {
                required: required
            },
            EmployeeVendorRelationshipDetails: {
                FormerEmployeeName: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('FormerEmployeeName')
                    })
                },
                FamilyMemberName: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('FamilyMemberName')
                    })
                },
                FriendName: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('FriendName')
                    })
                },
                VendorRelationshipWithAgencyClient: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('VendorRelationshipWithAgencyClient')
                    })
                },
                VendorOfFormerEmployee: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('VendorOfFormerEmployee')
                    })
                },
                VendorWasFormerEmployeeOfAgency: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('VendorWasFormerEmployeeOfAgency')
                    })
                },
                VendorHasRelationshipWithAnotherAgencyEmployee: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('VendorHasRelationshipWithAnotherAgencyEmployee')
                    })
                },
                Other: {
                    required: requiredIf(function () {
                        return this.ProdEXPAmend.EmployeeVendorRelationship.includes('Other')
                    })
                }
            }

        }
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        CompensationReceived() {
            return this.ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder == "true";
        },
        IsNoneSelected() {
            return this.ProdEXPAmend.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        ...mapGetters('prodexpamend', {
            ProdEXPAmend: 'ProdEXPAmend',
        }),
    },
    watch: {
        "ProdEXPAmend.EmployeeVendorRelationship": function (val) {
            var result = val;
            if (result.includes('NONE') && result.length > 1) {
                var index5 = result.indexOf('NONE');
                if (index5 > -1) {
                    if (index5 == 0) {
                        result.splice(index5, 1);
                    } else {
                        let arraLength = result.length;
                        for (var i = 0; i < arraLength; i++) {
                            if (i !== index5) {
                                result = val.splice(i, 1);
                            }
                        }
                        this.$set(this.ProdEXPAmend, "AnyGiftOrCompensationOfferedToYouToPlaceOrder", 'false');
                    }
                }
            }
            this.RelationshipVendors?.forEach(element => {
                if (!result.includes(element.value)){
                    this.ProdEXPAmend.EmployeeVendorRelationshipDetails[element.value] = null;
                }
            });
            val = result;
        },
        "ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder": function (val) {
            if (val == false || val == 'false') {
                this.$set(this.ProdEXPAmend, "CompensationReceived", null);
            }
        }
    },
    mounted() {
        this.formatDollars();
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    methods: {
        formatDollars() {
            this.IsCompensationDollarsValid = true;
            if (this.ProdEXPAmend.CompensationReceived && this.ProdEXPAmend.CompensationReceived != '') {
                var num = this.ProdEXPAmend.CompensationReceived.toString().replace(',', '');

                num = Number(num);
                if (!isNaN(num)) {
                    let temp = num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    this.ProdEXPAmend.CompensationReceived = temp;
                }
                else {
                    this.IsCompensationDollarsValid = false;
                    this.ProdEXPAmend.CompensationReceived = '';
                }
            }
        },
        SetDirty(propertyName) {
            this.$v.ProdEXPAmend.EmployeeVendorRelationshipDetails[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.ProdEXPAmend.EmployeeVendorRelationshipDetails[propertyName].$reset();
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Review",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({ name: "Production Expense Amend Review" });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },

        Previous() {
            if (this.id) {
                if(this.ProdEXPAmend.ChangeType == 'Both' || this.ProdEXPAmend.ChangeType == 'Banking') {
                    this.$router.push({
                        name: "Production Expense Amend Banking Info",
                        params: {
                            id: this.id,
                            isDraft: this.isDraft
                        }
                    });
                }
                if(this.ProdEXPAmend.ChangeType == 'Address') {
                    this.$router.push({
                        name: "Production Expense Amend Setup Info",
                        params: {
                            id: this.id,
                            isDraft: this.isDraft
                        }
                    });
                }
            } else {
                if(this.ProdEXPAmend.ChangeType == 'Both' || this.ProdEXPAmend.ChangeType == 'Banking') {
                    this.$router.push({
                        name: "Production Expense Amend Banking Info"
                    });
                }
                if(this.ProdEXPAmend.ChangeType == 'Address') {
                    this.$router.push({
                        name: "Production Expense Amend Setup Info"
                    });
                }
            }
        },
    }
}
</script>

<style>
.label-compensation {
    white-space: nowrap;
}

fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

input#compensation-dollar {
    background-image: url("data:image/svg+xml;utf8,<svg \
                                xmlns='http://www.w3.org/2000/svg' \
                                             version='1.1' height='40px' width='40px'>\
                                     <text x='2' y='22' \
                                fill='gray' font-size='15'>$</text></svg>");
    background-repeat: no-repeat;

}
.error-messages {
    font-size: 10px;
    color: red !important;
    font-family: "Open Sans";
    padding-left: 7px;
    padding-top: 5px;
}
</style>
